import _ from 'lodash';
import axios from 'axios';
import Popper from 'popper.js';
import jQuery from 'jquery';
import Pusher from 'pusher-js';
import 'bootstrap';

window._ = _;
window.axios = axios;
window.Popper = Popper;
window.$ = window.jQuery = jQuery;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    if (window.ECHO_CONFIG.broadcaster && ['pusher', 'reverb'].includes(window.ECHO_CONFIG.broadcaster)) {
        try {
            window.Pusher = Pusher;
        } catch (error) {
            console.error('Error loading Pusher:', error);
        }
    }
} catch (e) {
    console.error('Error in Echo configuration:', e);
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';
import { broadcaster } from 'laravel-echo-api-gateway';

const echoOptions = _.merge({
    broadcaster: window.ECHO_CONFIG.broadcaster || broadcaster },
    window.ECHO_CONFIG,
);

window.Echo = new Echo(echoOptions);
