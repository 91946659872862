
<CRow>
    <CCol class="lm-col-2 col-2 content-center">
        <CIcon class="lm-icon-warning" :content="$options.warning" />
    </CCol>
    <CCol class="text-caption">
        <div>
            Your password expires in <strong>{{ days }}</strong>.
        </div>
        Please
        <a href="#" @click.prevent="resetPassword" class="btn-link text-decoration-underline">
            <u>change your password.</u>
        </a>
    </CCol>
    <CCol @click.prevent="$emit('close-toast')" class="lm-col-2 col-2 btn text-black-50 content-center">
        <CIcon :content="$options.close" />
    </CCol>
</CRow>
