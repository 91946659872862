import './bootstrap.js';
import './plugins/google_analytics.js';
import './plugins/sentry_io.js';

import Vue from 'vue';
import CoreUiVue from '@coreui/vue';
import Router from "vue-router";
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueCompositionAPI from '@vue/composition-api';
import { iconsSet as icons } from './assets/icons/icons.js';
import http from './utils/http.js';
import router from './router';
import * as utils from './utils';
import App from './App.vue';
import vuetify from './plugins/vuetify.js';
import _ from 'lodash';

Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.config.performance = true
Vue.use(VueCompositionAPI);
Vue.use(PiniaVuePlugin)
Vue.use(CoreUiVue);
Vue.prototype.$log = console.log.bind(console);
Vue.prototype.$axios = http;
Vue.prototype.$utils = utils;
Vue.prototype._ = _;

const pinia = createPinia();
Vue.use(Router);

window.Vue = Vue;

new Vue({
    el: '#app',
    pinia,
    router,
    icons,
    template: '<App/>',
    vuetify,
    components: {
        App,
    }
});
