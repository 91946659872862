
<CRow>
    <CCol class="lm-col-2 col-2 content-center">
        <CIcon class="lm-icon-delete" :content="$options.success"/>
    </CCol>
    <CCol className="text-caption">
        <div>
            {{ message.type }} <strong>{{ message.value }}</strong> successfully deleted!
        </div>
    </CCol>
</CRow>
