import Router from 'vue-router';
import routes from './_routes.js';
import guards from './_guards.js';
import { useContainerStore } from "@store/containerStore.js";

const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes,
});

router.beforeEach(guards);

router.afterEach((to, from) => {
    const containerStore = useContainerStore();

    containerStore.setPageLoadingProgress(100);
});

export default router;
