import { useContainerStore } from "@store/containerStore.js";
import { useAuthStore } from "@store/authStore.js";
import { cookieExists } from "@utils";

export default async function (to, from, next) {
    const containerStore = useContainerStore();
    const authStore = useAuthStore();

    await containerStore.setPageLoadingProgress(5);

    if (await isRedirectToLandingPage(authStore, to)) {
        return next({ path: getLandingPage(authStore) });
    }

    if (requiresAuth(to)) {
        if (!await isAuthenticated(authStore)) {
            return next({ path: '/login', query: { redirect: to.fullPath } });
        }

        if (isFeatureDisabled(to)) {
            return next({ name: 'errors.503' });
        }

        if (isPasswordExpired(authStore) && to.path !== '/password/expired' && from.path !== '/password/expired') {
            console.log('password is expired')
            return false;
        }

        if (!isRoutePermitted(authStore, to)) {
            return next(from);
        }
    }

    next();
};

function isAuthenticated(authStore) {
    if (cookieExists('laravel_session')) {
        return authStore.isLoggedIn
            || authStore.getMe()
                .then(result => result.status === 'success')
                .catch(_ => false);
    }

    return false;
}

function isRoutePermitted(authStore, to) {
    return !to.meta.hasOwnProperty('requiredAbility')
        || hasAbility(authStore, to);
}

async function isRedirectToLandingPage(authStore, to) {
    return await isAuthenticated(authStore)
            && (to.path === '/login' || (to.path === '/' && !authStore.isAdmin))
}

function isFeatureDisabled(to)
{
    return !to.name?.startsWith('errors.')
        && (window?.FEATURES ?? [])
            .some(feature => !feature?.enabled && feature.paths.some(path => new RegExp(path).test(to.path)));
}

function isPasswordExpired(authStore) {
    return authStore.isPasswordExpired;
}

function requiresAuth(to) {
    return to.meta.requiresAuth;
}

function hasAbility(authStore, to) {
    return authStore.iHaveAbility(to.meta.requiredAbility);
}

function getLandingPage(authStore) {
    if (authStore.isAdmin) {
        return '/'; // path choice was arbitrary
    }

    if (isPasswordExpired(authStore)) { // place before admin if we require new admin users to reset password. This is left here for testing
        return '/password/expired';
    }

    if (authStore.iHaveAbility('list-poi')) {
        return '/groups';
    }

    if (authStore.iHaveAbility('list-audience')) {
        return '/audiences';
    }

    if (authStore.iHaveAbility('view-dashboard')) {
        return '/dashboardGroups';
    }
}
