import { defineStore } from "pinia";
import axios from "../utils/http";

const state = () => ({
    advertisers: [],
});

const getters = {
    advertisersList: (state) => state.advertisers,
};

const actions = {
    async getAdvertisers() {
        try {
            const { data } = await axios.get('/api/advertisers');

            data.unshift({
                id: null,
                name: 'All',
            });

            this.advertisers = data;
        } catch (e) {
            console.log(e); // TODO: Actually handle this error
        }
    },
};

export const useAdvertisersStore = defineStore("advertisersStore", {
    state,
    getters,
    actions,
});
