import Vue from "vue";
import * as Sentry from "@sentry/vue";
import router from "../router/index.js";

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: "https://debf34396f96d0c1e0a819ca7bbd224f@o4506236306391040.ingest.sentry.io/4506237358964736",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/app\.lean\.media\/api/],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
