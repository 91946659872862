import { defineStore, getActivePinia } from "pinia";
import { useContainerStore } from "./containerStore.js";
import EventBus from "../event-bus";
import axios from "../utils/http";
import router from "../router";

const PERMISSIONS = [
    'read-only',
    'data-entry',
    'full-access',
    '!read-only',
    '!data-entry',
    '!full-access',
];

function hasPermission(permissions, permission) {
    if (!PERMISSIONS.includes(permission)) {
        throw new Error(`Invalid permission method: ${permission}`);
    }

    permission = permission.replace(/-./g, x => x.toUpperCase()[1]);

    return (permission[0] === '!') ? !permissions[permission.substring(1)]() : permissions[permission]();
}

const state = () => ({
    me: {},
    inspected: null,
    amAdmin: false,
});

const getters = {
    isAdmin: (state) => state.amAdmin,
    isLoggedIn: (state) => !!state.me.id,
    isPasswordExpired: (state) => !state.amAdmin && new Date() >= new Date(state.me.password_expires_at),
    loggedInUser: (state) => state.me,
    iHaveAbility (state) {
        return (ability) => this.isLoggedIn && (this.isAdmin || state.me?.abilities.includes(ability));
    },
    AudienceAccess() {
        return (permission) => {
            return hasPermission({
                readOnly: () => !this.iHaveAbility('build-audience'),
                fullAccess: () => this.iHaveAbility('build-audience'),
            }, permission);
        };
    },
    PolyAccess() {
        return (permission) => {
            return hasPermission({
                readOnly: () => !this.iHaveAbility('data-entry'),
                dataEntry: () => this.iHaveAbility('data-entry'),
                fullAccess: () => this.iHaveAbility('build-poi'),
            }, permission);
        };
    },
    DashboardAccess() {
        return (permission) => {
            return hasPermission({
                readOnly: () => !this.iHaveAbility('build-dashboard'),
                fullAccess: () => this.iHaveAbility('build-dashboard'),
            }, permission);
        };
    },
};

const actions = {
    async getMe() {
        let result = {
            status: 'undefined',
            payload: {},
        };

        try {
            const response = await axios.get('/api/me', {
                toastBag: [{ type: 'PasswordExpiring' }],
            });

            this.me = response.data;
            this.amAdmin = this.me.roles.includes('admin');

            if (response.data?.id) {
                // Remove existing user_id from dataLayer
                window.dataLayer?.forEach((item, existingUserId) => {
                    if (('event' in item) && ('user_id' in item)) window.dataLayer.splice(existingUserId, 1);
                });

                // Add new user_id to dataLayer
                window.dataLayer?.push({
                    'event': 'userData',
                    'user_id': response.data.id
                });
            }

            result.status = 'success';
            result.payload = response.data;

            EventBus.$emit('user:authenticated', result.payload);

            return result;
        } catch (error) {
            console.log(error); // TODO: Actually handle this error

            result.status = 'failed';
            result.payload = error.response.data;

            return result;
        }
    },
    async login({ login, password }) {
        const containerStore = useContainerStore();
        containerStore.setPageLoadingProgress(5);

        let result = {
            status: 'undefined',
            payload: {},
        };

        try {
            await axios.get('/sanctum/csrf-cookie');

            containerStore.setPageLoadingProgress(30);
        } catch (error) {
            console.log(error.response); // TODO: Actually handle this error

            result.status = 'failed';
            result.payload = error.response.data;

            containerStore.setPageLoadingProgress(100);

            return result;
        }

        try {
            await axios.post('/login', {
                login: login,
                password: password,
            });

            containerStore.setPageLoadingProgress(60);

            return await this.getMe();
        } catch (error) {
            console.log(error); // TODO: Actually handle this error

            result.status = 'failed';
            result.payload = error.response.data;

            containerStore.setPageLoadingProgress(100);

            return result;
        }
    },
    async logout() {
        try {
            await axios
                .post('/logout')
                .then(_ => {
                    getActivePinia()._s.forEach(store => store.$reset());
                    router.push({ path: '/login' });
                });

            this.me = {};
        } catch (error) {
            console.log(error); // TODO: Actually handle this error

            return false;
        }

        return true;
    },
};

export const useAuthStore = defineStore("authStore", {
    state,
    getters,
    actions,
});
