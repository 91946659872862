<template>
    <CRow>
        <CCol class="lm-col-2 col-2 content-center">
            <CIcon class="lm-icon-delete" :content="$options.success"/>
        </CCol>
        <CCol className="text-caption">
            <div>
                {{ message.type }} <strong>{{ message.value }}</strong> successfully deleted!
            </div>
        </CCol>
    </CRow>
</template>

<script>
import { cilCheckAlt } from '@coreui/icons';

export default {
    name: "Delete",
    success: cilCheckAlt,
    props: {
        message: Object,
    },
}
</script>

<style lang="scss">

    .lm-col-2.col-2 {
        max-width: 12%;
        padding: 0;
    }

    .lm-icon-delete {
        color: #e55353;
    }

    .Vue-Toastification__container.top-right .Vue-Toastification__toast.Delete {
        top: 4em;
        min-height: 0;
        padding: 12px 14px;
        border-radius: 5px;
        border: thin solid rgb(202, 143, 143, 0.92);
        border-left-width: .4em;
        border-left-color: #e55353;
        background-color: rgb(239, 226, 226, 0.92);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        color: #444444;
    }

</style>
