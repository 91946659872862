import Vue from "vue";
import { GTM_CONTAINER_ID } from "../utils/constants.js";
import router from "../router/index.js";

if (process.env.NODE_ENV === 'production') {
    import('@gtm-support/vue2-gtm').then(VueGtm => {
        Vue.use(VueGtm.default, {
            id: GTM_CONTAINER_ID,
            vueRouter: router,
        });
    }).catch(error => {
        console.error('Error loading GTM:', error);
    });
}
