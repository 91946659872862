<template>
    <CRow>
        <CCol class="lm-col-2 col-2 content-center">
            <CIcon class="lm-icon-warning" :content="$options.warning" />
        </CCol>
        <CCol class="text-caption">
            <div>
                Your password expires in <strong>{{ days }}</strong>.
            </div>
            Please
            <a href="#" @click.prevent="resetPassword" class="btn-link text-decoration-underline">
                <u>change your password.</u>
            </a>
        </CCol>
        <CCol @click.prevent="$emit('close-toast')" class="lm-col-2 col-2 btn text-black-50 content-center">
            <CIcon :content="$options.close" />
        </CCol>
    </CRow>
</template>

<script>
import router from '../../../router';
import EventBus from '../../../event-bus.js';
import { cilWarning, cilX } from '@coreui/icons';
import { v1 as uuidv1 } from 'uuid';
import { DateTime } from "luxon";

export default {
    name: "ExpiredPassword",
    warning: cilWarning,
    close: cilX,
    props: {
        message: Number, // days
    },
    computed: {
        days() {
            return this.message < 1 ? 'less than 1 day' : this.message === 1 ? `${this.message} day` : `${this.message} days`;
        },
    },
    methods: {
        async resetPassword() {
            this.$emit('close-toast');

            await router.push({ name: 'account.edit' }).catch(() => {});

            EventBus.$emit('password:expiring');
        },
    },
    created() {
        localStorage.setItem('password.expiration.viewed', JSON.stringify({
            id: uuidv1(),
            timestamp: DateTime.now(),
        }));
    },
}
</script>

<style lang="scss">

    .lm-col-2.col-2 {
        max-width: 12%;
        padding: 0;
    }

    .lm-icon-warning {
        color: #b38b2b;
    }

    .Vue-Toastification__container.top-right .Vue-Toastification__toast.PasswordExpiring  {
        top: 4em;
        min-height: 0;
        padding: 12px 14px;
        border-radius: 5px;
        border: thin solid rgb(218, 202, 165, 0.92);
        border-left-width: .4em;
        border-left-color: #b38b2b;
        background-color: rgb(245, 232, 204, 0.92);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        color: #444444;
    }

</style>
