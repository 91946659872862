<template>
    <div id="app-wrapper">
        <v-app>
            <v-progress-linear :active="isPageLoading" absolute top
                               :value="pageLoadingProgress"/>

            <router-view></router-view>
        </v-app>
    </div>
</template>

<script>
    import { computed } from "@vue/composition-api";
    import { onMounted } from '@vue/composition-api';
    import { useContainerStore } from "@store/containerStore.js";
    import { useAdvertisersStore } from "@store/advertisersStore.js";
    import EventBus from '@/event-bus.js';

    const GEO_COMPOSITION_CATEGORIES = ['states', 'counties', 'zipcodes'];

    function fetchGeoCompositionCategories() {
        GEO_COMPOSITION_CATEGORIES.forEach(category => {
            if (!!localStorage.getItem(category))
                return;

            console.info(`Loading filter option: '${category}' into local storage.`);
            this.$axios.get(`/api/compositions/${category}`)
                .then(response => setGeoCompositionCategoriesInLocalStorage(category, response.data.data))
                .catch(console.log);
        });
    }

    function setGeoCompositionCategoriesInLocalStorage(category, geoComposition) {
        geoComposition.forEach(item => {
            item.type = category;
            item.remove = false;
        });

        localStorage.setItem(category, JSON.stringify(response.data.data));
        EventBus.$emit('loading:complete', category);
    }

    export default {
        name: 'App',
        setup() {
            const advertisersStore = useAdvertisersStore();
            function getAdvertisers() {
                if (advertisersStore.advertisersList.length !== 0) {
                    return;
                }

                advertisersStore.getAdvertisers();
            }
            onMounted(() => EventBus.$once('user:authenticated', getAdvertisers));

            const containerStore = useContainerStore();
            const isPageLoading = computed(() => containerStore.isPageLoading);
            const pageLoadingProgress = computed(() => containerStore.pageLoadingProgress);

            return {
                isPageLoading,
                pageLoadingProgress,
            }
        },
    }
</script>

<style lang="scss">

</style>
