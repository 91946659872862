<template>
    <CRow>
        <CCol class="lm-col-2 col-2 content-center">
            <CIcon class="lm-icon-success" :content="$options.success" />
        </CCol>
        <CCol className="text-caption">
            <div>
                {{ this.message.type }} <strong>{{ this.message.value }}</strong> successfully saved!
            </div>
        </CCol>
    </CRow>
</template>

<script>
import { cilCheckAlt } from '@coreui/icons';

export default {
    name: "Save",
    success: cilCheckAlt,
    props: {
        message: Object,
    },
}
</script>

<style lang="scss">

    .lm-col-2.col-2 {
        max-width: 12%;
        padding: 0;
    }

    .lm-icon-success {
        color: #2eb85c;
    }

    .Vue-Toastification__container.top-right .Vue-Toastification__toast.Save  {
        top: 4em;
        min-height: 0;
        padding: 12px 14px;
        border-radius: 5px;
        border: thin solid rgb(143, 202, 149, 0.92);
        border-left-width: .4em;
        border-left-color: #2eb85c;
        background-color: rgb(226, 239, 229, 0.92);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        color: #444444;
    }

</style>
