import { defineStore, acceptHMRUpdate } from "pinia";

const state = () => ({
    sidebarStatus: 'responsive',
    isSidebarOpen: true,
    isLoading: false,
    loadingProgress: 0,
});

const getters = {
    sidebarShow: (state) => state.sidebarStatus,
    isNavbarDrawerOpen: (state) => state.isSidebarOpen,
    pageLoadingProgress: (state) => state.loadingProgress,
    isPageLoading: (state) => state.isLoading,
};

const actions = {
    setPageLoadingProgress(percentage) {
        this.isLoading = true;
        this.loadingProgress = percentage;

        if (percentage >= 100) {
            setTimeout(() => {
                this.isLoading = false;
            }, 250);
        }
    },
    setSidebarShow(value) {
        this.sidebarStatus = value;
    },
    toggleSidebarDesktop() {
        this.isSidebarOpen = !this.isSidebarOpen;
    },
};

export const useContainerStore = defineStore("containerStore", {
    state,
    getters,
    actions,
});

// hot module replacement
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useContainerStore, import.meta.hot));
}
