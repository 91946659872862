// Container
const Container = () => import('../containers/Container.vue');

// Auth
const Login = () => import('../features/shared/auth/Login.vue');
const ForgotPassword = () => import('../features/shared/auth/ForgotPassword.vue');
const ResetPasswordFromLink = () => import('../features/shared/auth/ResetPassword.vue');

// Views
const MyAudiences = () => import('../features/audience-builder/audience/MyAudiences.vue');
const AudienceBuilder = () => import('../features/audience-builder/audience/AudienceBuilder.vue');
const AudienceSummary = () => import('../features/audience-builder/audience/AudienceSummary.vue');
const MyCompositions = () => import('../features/audience-builder/compositions/MyCompositions.vue');
const CompositionBuilder = () => import("../features/audience-builder/compositions/CompositionBuilder.vue");
const MyPoiGroups = () => import('../features/poi-builder/MyPoiGroups.vue');
const MapWrapper = () => import('../features/poi-builder/map/MapWrapper.vue');

const UserList = () => import('../features/shared/users/List.vue');
const UserEdit = () => import('../features/shared/users/Edit.vue');

const MyDashboardGroups = () => import('../features/dashboard-builder/MyDashboardGroups.vue');
const DashboardGroupWrapper = () => import('../features/dashboard-builder/groups/Wrapper.vue');
const DashboardGroup = () => import('../features/dashboard-builder/groups/DashboardGroup.vue');
const DashboardGroupEdit = () => import('../features/dashboard-builder/groups/Edit.vue');
const DashboardWrapper = () => import('../features/dashboard-builder/groups/dashboard/Wrapper.vue');
const Dashboard = () => import('../features/dashboard-builder/groups/dashboard/Dashboard.vue');
const DashboardEdit = () => import('../features/dashboard-builder/groups/dashboard/Edit.vue');

const MyAccount = () => import('../features/shared/users/MyAccount.vue');
const ResetPassword = () => import('../features/shared/users/ResetPassword.vue');

// Errors
const Error403 = () => import('../features/shared/errors/403.vue');
const Error404 = () => import('../features/shared/errors/404.vue');
const Error500 = () => import('../features/shared/errors/500.vue');
const Error503 = () => import('../features/shared/errors/503.vue');

export default [
    {
        path: '/',
        name: '',
        component: Container,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '/groups',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: "/",
                        name: "pois.groups",
                        component: MyPoiGroups,
                        meta: {
                            label: 'Poi Groups',
                            requiresAuth: true,
                            requiredAbility: 'list-poi',
                        },
                    },
                    {
                        path: ':id/map',
                        name: 'pois.map',
                        component: MapWrapper,
                        meta: {
                            label: 'Polygon Builder',
                            requiresAuth: true,
                            requiredAbility: 'list-poi',
                        },
                    },
                ],
            },
            {
                path: 'audiences',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'audiences',
                        component: MyAudiences,
                        meta: {
                            label: 'Audiences',
                            requiresAuth: true,
                            requiredAbility: 'list-audience',
                        },
                    },
                    {
                        path: 'build',
                        name: 'audiences.build',
                        component: AudienceBuilder,
                        meta: {
                            label: 'Audience Builder',
                            requiresAuth: true,
                            requiredAbility: 'build-audience',
                        },
                    },
                    {
                        path: ':id',
                        name: 'audiences.detail',
                        component: AudienceSummary,
                        meta: {
                            label: 'Audience Detail',
                            requiresAuth: true,
                            requiredAbility: 'list-audience',
                        },
                    },
                    {
                        path: 'edit/:id',
                        name: 'audiences.edit',
                        component: AudienceBuilder,
                        meta: {
                            label: 'Edit Audience',
                            requiresAuth: true,
                            requiredAbility: 'build-audience',
                        },
                    },
                ],
            },
            {
                path: 'compositions',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'compositions',
                        component: MyCompositions,
                        meta: {
                            label: 'Geography Library',
                            requiresAuth: true,
                            requiredAbility: 'list-audience',
                        },
                    },
                    {
                        path: 'build',
                        name: 'compositions.build',
                        component: CompositionBuilder,
                        meta: {
                            label: 'Composition Builder',
                            requiresAuth: true,
                            requiredAbility: 'build-audience',
                        }
                    },
                    {
                        path: 'edit/:id',
                        name: 'compositions.edit',
                        component: CompositionBuilder,
                        props: true,
                        meta: {
                            label: 'Edit Composition',
                            requiresAuth: true,
                            requiredAbility: 'list-audience',
                        },
                    },
                ]
            },
            {
                path: 'account',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: 'edit',
                        name: 'account.edit',
                        component: MyAccount,
                        meta: {
                            label: 'My Account',
                            requiresAuth: true,
                            requiredAbility: '*',
                        },
                    },
                ],
            },
            {
                path: '/dashboardGroups',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'dashboardGroups',
                        component: MyDashboardGroups,
                        meta: {
                            label: 'My Dashboard Groups',
                            requiresAuth: true,
                            requiredAbility: 'view-dashboard',
                        },
                    },
                    {
                        path: 'new',
                        name: 'dashboardGroup.new',
                        component: DashboardGroupEdit,
                        meta: {
                            label: 'Dashboard Group New',
                            requiresAuth: true,
                            requiredAbility: 'build-dashboard',
                        },
                    },
                    {
                        path: 'clone',
                        name: 'dashboardGroup.clone',
                        component: DashboardGroupEdit,
                        props: true,
                        meta: {
                            label: 'Dashboard Group Clone',
                            requiresAuth: true,
                            requiredAbility: 'build-dashboard',
                        },
                    },
                    {
                        path: ':id',
                        component: DashboardGroupWrapper,
                        props: true,
                        meta: {
                            requiresAuth: true,
                            requiredAbility: 'view-dashboard',
                        },
                        children: [
                            {
                                path: '',
                                name: 'dashboardGroup',
                                component: DashboardGroup,
                                props: true,
                                meta: {
                                    label: 'Dashboard Group',
                                    requiresAuth: true,
                                    requiredAbility: 'view-dashboard',
                                },
                            },
                            {
                                path: 'settings',
                                name: 'dashboardGroup.edit',
                                component: DashboardGroupEdit,
                                props: true,
                                meta: {
                                    label: 'Dashboard Group Settings',
                                    requiresAuth: true,
                                    requiredAbility: 'build-dashboard',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: '/dashboardGroups/:groupId/dashboard',
                component: {
                    render(c) {
                        return c('router-view');
                    }
                },
                children: [
                    {
                        path: 'new',
                        name: 'dashboard.new',
                        component: DashboardEdit,
                        props: true,
                        meta: {
                            label: 'Dashboard New',
                            requiresAuth: true,
                            requiredAbility: 'build-dashboard',
                        },
                    },
                    {
                        path: ':id/',
                        component: DashboardWrapper,
                        props: true,
                        meta: {
                            label: 'Dashboard Wrapper',
                            requiresAuth: true,
                            requiredAbility: 'view-dashboard',
                        },
                        children: [
                            {
                                path: '',
                                name: 'dashboard',
                                component: Dashboard,
                                props: true,
                                meta: {
                                    label: 'Dashboard',
                                    requiresAuth: true,
                                    requiredAbility: 'view-dashboard',
                                },
                            },
                            {
                                path: 'settings',
                                name: 'dashboard.edit',
                                component: DashboardEdit,
                                props: true,
                                meta: {
                                    label: 'Dashboard Settings',
                                    requiresAuth: true,
                                    requiredAbility: 'build-dashboard',
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/users',
        component: Container,
        children: [
            {
                path: '',
                component: UserList,
                meta: {
                    label: 'Users',
                    requiresAuth: true,
                    requiredAbility: 'admin',
                },
            },
            {
                path: 'new',
                name: 'users.new',
                component: UserEdit,
                meta: {
                    label: 'New User',
                    requiresAuth: true,
                    requiredAbility: 'admin',
                },
            },
            {
                path: ':id',
                name: 'users.edit',
                component: UserEdit,
                props: true,
                meta: {
                    label: 'Edit User',
                    requiresAuth: true,
                    requiredAbility: 'admin',
                },
            },
        ],
    },
    {
        path: '/password/forgot',
        name: 'password.forgot',
        component: ForgotPassword,
    },
    {
        path: '/password/reset/:token',
        name: 'password.reset',
        component: ResetPasswordFromLink,
    },
    {
        path: '/password/expired',
        name: 'password.expired',
        component: ResetPassword,
        meta: {
            requiresAuth: true,
            requiredAbility: '*',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: "/errors",
        redirect: "/errors/404",
        name: 'Errors',
        component: {
            render(c) {
                return c('router-view');
            },
        },
        meta: {
            label: 'Errors',
        },
        children: [
            {
                path: '403',
                name: 'errors.403',
                component: Error403,
                meta: {
                    label: '403',
                },
            },
            {
                path: '404',
                name: 'errors.404',
                component: Error404,
                meta: {
                    label: '404',
                },
            },
            {
                path: '500',
                name: 'errors.500',
                component: Error500,
                meta: {
                    label: '500',
                },
            },
            {
                path: '503',
                name: 'errors.503',
                component: Error503,
                meta: {
                    label: '503',
                },
            },
        ]
    },
    {
        path: '*',
        component: Error404,
    },
];
