import Vue from 'vue';
import axios from 'axios';
import Toast, { POSITION } from 'vue-toastification';
import "vue-toastification/dist/index.css";
import { createToast, handleToastErrors, uncheckedErrorExists } from './index.js';
import _ from "lodash";

const options = {
    timeout: 10000,
    position: POSITION.BOTTOM_CENTER,
}

Vue.use(Toast, options);

const http = axios.create();

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
http.defaults.withCredentials = true;
http.interceptors.request.use(
    config => {
        config.headers['X-Socket-Id'] = window.Echo?.socketId();

        return config;
    }
);
http.interceptors.response.use(
    response => {
        if (response.config.url === '/logout') {
            if (!!localStorage.getItem('password.expiration.viewed')) {
                localStorage.removeItem('password.expiration.viewed')
            }
        }

        if (response.config.toastBag) {
            _.each(response.config.toastBag, item => {
                let { content, options } = createToast(response, item);

                if (!content || !options) {
                    return;
                }

                Vue.$toast(content, options);
            });
        }

        return response;
    },
    err => {
        if (!err.response) return Promise.reject(err);

        if (uncheckedErrorExists(err))
            Vue.$toast.error('Oops, something went wrong', { timeout: 3200 });

        handleToastErrors(err);

        return Promise.reject(err);
    }
);

export default http;
