import _ from 'lodash';
import { POSITION } from "vue-toastification";
import { DateTime } from "luxon";

// notification components
import PasswordExpiring from "../features/shared/notifications/PasswordExpiring.vue";
import Save from "../features/shared/notifications/Save.vue";
import Delete from "../features/shared/notifications/Delete.vue";

// TODO - move to constants.js
export const constants = {
    AUDIENCE_META: {
        id: null,
        name: null,
        lookback: null,
        export_filename: null,
        radius: null,
        is_miles: null,
        criteria: null,
        details: null,
        build_with_polygon: null,
        is_building: false,
        activations: [],
        composition_ids: [],
        poi_groups: [],
    },
    POI_GROUP_META: {
        id: null,
        name: null,
        is_processing_file: false,
    },
    DASHBOARD_GROUP_META: {
        id: null,
        name: null,
        options: null,
    },
    DASHBOARD_META: {
        id: null,
        name: null,
        chart_layouts: [],
        options: null,
    },
};

function _isCoordArray(latlng) {
    return _.isArray(latlng) && latlng.length === 2 && _.isFinite(latlng[0]) && _.isFinite(latlng[1]);
}

export function normalizeLatLng(latlngs) {
    return _.map(latlngs, latlng => {
        if (latlng.lat === undefined && !_isCoordArray(latlng)) {
            return normalizeLatLng(latlng);
        }

        if (!_isCoordArray(latlng)) {
            return [latlng.lng, latlng.lat];
        } else {
            return [latlng[0], latlng[1]];
        }
    });
}

export function leafletToWktLatLngs(latlngs) {
    // let coordinates = normalizeLatLng(latlngs);

    let coordinates = latlngs;

    if (coordinates[0].lat === undefined && !_isCoordArray(coordinates[0])) {
        _.forEach(coordinates, (group, index) => {
            coordinates[index].push(group[0]);
        });
    } else {
        coordinates.push(coordinates[0]);
    }

    coordinates = normalizeLatLng(coordinates);

    return coordinates;
}

export function polyLatLngsToCentroid(arr) {
    let latlngs = _.map(arr, n => _.map(n, e => _.compact(e)))[0];

    let twoTimesSignedArea = 0;
    let cxTimes6SignedArea = 0;
    let cyTimes6SignedArea = 0;

    const length = latlngs.length;

    let x = i => latlngs[i % length][0];
    let y = i => latlngs[i % length][1];

    latlngs.forEach((_, i) => {
        let twoSA = x(i)*y(i+1) - x(i+1)*y(i);
        twoTimesSignedArea += twoSA;
        cxTimes6SignedArea += (x(i) + x(i+1)) * twoSA;
        cyTimes6SignedArea += (y(i) + y(i+1)) * twoSA;
    });

    let sixSignedArea = 3 * twoTimesSignedArea;

    return [ cyTimes6SignedArea / sixSignedArea, cxTimes6SignedArea / sixSignedArea];
}

export function convertStateToAbbreviation(stateLabel) {
    const stateLabelValues = [
        { 'label':'Alabama', 'value': 'AL' },
        { 'label':'Alaska', 'value': 'AK'},
        { 'label':'American Samoa', 'value': 'AS'},
        { 'label':'Arizona', 'value': 'AZ'},
        { 'label':'Arkansas', 'value': 'AR'},
        { 'label':'California', 'value': 'CA'},
        { 'label':'Colorado', 'value': 'CO'},
        { 'label':'Connecticut', 'value': 'CT'},
        { 'label':'Delaware', 'value': 'DE'},
        { 'label':'District of Columbia', 'value': 'DC'},
        { 'label':'States of Micronesia', 'value': 'FM'},
        { 'label':'Florida', 'value': 'FL'},
        { 'label':'Georgia', 'value': 'GA'},
        { 'label':'Guam', 'value': 'GU'},
        { 'label':'Hawaii', 'value': 'HI'},
        { 'label':'Idaho', 'value': 'ID'},
        { 'label':'Illinois', 'value': 'IL'},
        { 'label':'Indiana', 'value': 'IN'},
        { 'label':'Iowa', 'value': 'IA'},
        { 'label':'Kansas', 'value': 'KS'},
        { 'label':'Kentucky', 'value': 'KY'},
        { 'label':'Louisiana', 'value': 'LA'},
        { 'label':'Maine', 'value': 'ME'},
        { 'label':'Marshall Islands', 'value': 'MH'},
        { 'label':'Maryland', 'value': 'MD'},
        { 'label':'Massachusetts', 'value': 'MA'},
        { 'label':'Michigan', 'value': 'MI'},
        { 'label':'Minnesota', 'value': 'MN'},
        { 'label':'Mississippi', 'value': 'MS'},
        { 'label':'Missouri', 'value': 'MO'},
        { 'label':'Montana', 'value': 'MT'},
        { 'label':'Nebraska', 'value': 'NE'},
        { 'label':'Nevada', 'value': 'NV'},
        { 'label':'New Hampshire', 'value': 'NH'},
        { 'label':'New Jersey', 'value': 'NJ'},
        { 'label':'New Mexico', 'value': 'NM'},
        { 'label':'New York', 'value': 'NY'},
        { 'label':'North Carolina', 'value': 'NC'},
        { 'label':'North Dakota', 'value': 'ND'},
        { 'label':'Northern Mariana Islands', 'value': 'MP'},
        { 'label':'Ohio', 'value': 'OH'},
        { 'label':'Oklahoma', 'value': 'OK'},
        { 'label':'Oregan', 'value': 'OR'},
        { 'label':'Palau', 'value': 'PW'},
        { 'label':'Pennsilvania', 'value': 'PA'},
        { 'label':'Puerto Rico', 'value': 'PR'},
        { 'label':'Rhode Island', 'value': 'RI'},
        { 'label':'South Carolina', 'value': 'SC'},
        { 'label':'South Dakota', 'value': 'SD'},
        { 'label':'Tennessee', 'value': 'TN'},
        { 'label':'Texas', 'value': 'TX'},
        { 'label':'Utah', 'value': 'UT'},
        { 'label':'Vermont', 'value': 'VT'},
        { 'label':'Virgin Islands', 'value': 'VI'},
        { 'label':'Virginia', 'value': 'VA'},
        { 'label':'Washington', 'value': 'WA'},
        { 'label':'West Virginia', 'value': 'WV'},
        { 'label':'Wisconsin', 'value': 'WI'},
        { 'label':'Wyoming', 'value': 'WY'}
    ];

    return _.find(stateLabelValues, ['label', stateLabel]).value;
}

export function asset(url) {
    return `${window.ASSET_URL}${url}`;
}

/**
 * Check to see if a cookie exists (i.e. not expired).
 *
 * @param name
 * @returns {boolean}
 */
export function cookieExists(name) {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`));
}

/**
 * Check to see if an error exists without toastBag or 401, 422 status exists.
 *
 * @param error
 * @returns {boolean}
 */
export function uncheckedErrorExists(error) {
    const errorHasToastSafe = Boolean(JSON.parse(error?.response?.config?.data ?? '{}')?.toastSafe);
    const errorInApprovedStatus = [401, 422].includes(error?.response?.status);
    const errorNotInToastSafe = !Object
        .keys(error?.response?.data?.errors ?? {})
        .some(toastSafe => JSON.parse(error?.response?.config?.data ?? '{}')?.toastSafe?.includes(toastSafe));

    if (errorInApprovedStatus) {
        return false;
    }

    if (errorHasToastSafe) {
        return errorNotInToastSafe;
    }

    return false;
}

/**
 * Handle any errors that are in the toastSafe array.
 *
 * @param error
 * @returns {void}
 */
export function handleToastErrors(error) {
    const toastSafe = JSON.parse(error?.response?.config?.data ?? '{}').toastSafe ?? [];
    const errors = error?.response?.data?.errors ?? {};

    _.forEach(errors, (value, key) => {
        if (toastSafe.includes(key)) {
            const errorMessage = JSON.stringify(Array.isArray(value) ? value[0] : value);
            Vue.$toast.error(errorMessage, { timeout: 3200 });
        }
    });
}

const notifications = {
    PasswordExpiring,
    Save,
    Delete,
}

export function daysToExpiredPassword(user) {
    let diff = DateTime.fromISO(user.password_expires_at).diff(DateTime.now(), 'days');

    if (Math.round(diff.days) <= 7 && diff.days > 0) {
        if (diff.days < 1) {
            return diff.days;
        }

        return Math.round(diff.days);
    }

    return 0;
}

/**
 * Check to see if a local storage variable needs to be set
 * so as the user does not see the notification twice.
 *
 * @param response
 * @returns boolean
 */
function passwordExpiringNeedsSet(response) {
    if (response.config.url === '/api/me' && response.data) {
        if (!!localStorage.getItem('password.expiration.viewed')) { // clear storage after 12 hours
            let datetime = DateTime.fromISO(JSON.parse(localStorage.getItem('password.expiration.viewed')).timestamp);

            if (DateTime.now().diff(datetime, 'hours').hours >= 12) {
                localStorage.removeItem('password.expiration.viewed');
            }
        }

        if (!localStorage.getItem('password.expiration.viewed') && daysToExpiredPassword(response.data)) {
            return true;
        }
    }

    return false;
}

/**
 * Create a toast object to be used in vue-toastification.
 *
 * @param response
 * @param item
 * @returns Object
 */
export function createToast(response, item) {
    let toast = {
        options: {
            timeout: 3200,
            position: POSITION.TOP_RIGHT,
            icon: false,
            hideProgressBar: true,
            closeButton: false,
            toastClassName: `${item.type}`,
        },
        content: {
            component: notifications[item.type],
            props: {
                message: item.message,
            },
        }
    }

    if (item.type === 'PasswordExpiring') {
        if (passwordExpiringNeedsSet(response)) {
            _.merge(toast, {
                options: {
                    timeout: false,
                    closeOnClick: false,
                },
                content: {
                    props: {
                        message: daysToExpiredPassword(response.data),
                    }
                }
            });
        } else {
            return {};
        }

    }

    return toast;
}
