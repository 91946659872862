
<CRow>
    <CCol class="lm-col-2 col-2 content-center">
        <CIcon class="lm-icon-success" :content="$options.success" />
    </CCol>
    <CCol className="text-caption">
        <div>
            {{ this.message.type }} <strong>{{ this.message.value }}</strong> successfully saved!
        </div>
    </CCol>
</CRow>
